class DynamicQuoteConcepts {
  constructor() {
    this._nextConceptNumber = this._getInitialConceptNumber()

    this._addEventListeners()
  }

  _addEventListeners() {
    _class = this
    $('.add-concept').on('click', function() {
      $categorySection = $(this).parents('.quote-concepts')
      $reference = $categorySection.find('.quote-concept').first()
      $newConcept = $reference.clone()

      _class._prepareNewElement($newConcept)

      $newConcept.insertAfter($categorySection.find('.quote-concept').last())
    })

    $('.quote-concepts').on('click', '.quote-concept button.destroy-concept', function(e) {
      e.preventDefault()

      $categorySection = $(this).parents('.quote-concepts')
      if ($categorySection.find('.quote-concept:not(:hidden)').length <= 1) {
        return
      }

      $concept = $(this).parents('.quote-concept')
      $concept.hide(function() {
        $concept.find('input[name$="[_destroy]"]').val(true)
        $concept.find('input[name$="[quantity]"]').val(0).change()
      })
    })
  }

  _getInitialConceptNumber() {
    return $('.quote-concept').length
  }

  _getNextConceptNumber() {
    return this._nextConceptNumber++
  }

  _prepareNewElement($newElement) {
    conceptNumber = this._getNextConceptNumber()
    $newElement.find("input[name$='[concept]'], input[name$='[quantity]'], input[name$='[unit_price]']")
               .val("")
    $newElement.find('input[name$="[_destroy]"]').val(false)
    $newElement.find("input.subtotal").val("0")

    $newElement.find("input, select").each(function() {
      inputName = $(this).attr('name')
      $(this).attr('name', inputName.replace(/\[\d+\]/g, "[" + conceptNumber + "]"))

      if($(this).is("[id]")) {
        inputId = $(this).attr("id")
        $(this).attr('id', inputId.replace(/_\d+_/g, "_" + conceptNumber + "_"))
      }
    })

    $newElement.find("label").each(function() {
      labelFor = $(this).attr('for')
      $(this).attr('for', labelFor.replace(/_\d+_/g, "_" + conceptNumber + "_"))
    })
    $newElement.show()
  }
}

$(document).on('turbolinks:load', function() {
  new DynamicQuoteConcepts()
})
