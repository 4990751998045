$(document).on('turbolinks:load', function() {
  $("body").on("change paste keyup", ".quantity, .unit_price", function() {
    $form = $('form')
    $row = $(this).parents('.form-row');
    quantity = parseFloat($row.find('input.quantity').val())
    unit_price = parseFloat($row.find('input.unit_price').val())
    subtotal = quantity * unit_price
    subtotal = isNaN(subtotal) ? 0 : subtotal.toFixed(2)

    $row.find("input.subtotal").val(subtotal)

    formSubtotal = 0.0

    $form.find("input.subtotal").each(function(index, element) {
      formSubtotal += parseFloat(element.value)
    });

    $form.find("#form_subtotal").val(formSubtotal.toFixed(2))
    $form.find("#form_iva").val((formSubtotal * 0.16).toFixed(2))
    $form.find("#form_total").val((formSubtotal * 1.16).toFixed(2))
  });
});
