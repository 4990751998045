class DrawFinanceGraph {
  constructor() {
    _obj = this._loadIncomeOutflowData()
    _incomeColor = '#55dd00';
    _outflowColor = '#f90004';
    _totalColor = '#000000';
    google.charts.load('current', {packages: ['corechart', 'line']}).then(this._drawChart);
  }

  _loadIncomeOutflowData() {
    var dataResponse;
    $.ajax({
      async: false,
      url: "finances/income_expenses_by_month",
      method: "GET",
      contentType: "application/json",
      success: function (response) {
        dataResponse = response;
      }
    });
    return dataResponse;
  }

  _drawChart() {
    var data = new google.visualization.DataTable();
    var $graph = $('#finances-graph');

    data.addColumn('string', 'DATE');
    data.addColumn('number', $graph.data('income'));
    data.addColumn('number', $graph.data('outflow'));
    data.addColumn('number', $graph.data('total'));

    var outputData = [];
    for (var i in this._obj) {
      var income = this._obj[i].income
      var outflow = this._obj[i].outflow
      outputData.push([this._obj[i].month, income, outflow, income - outflow]);
    }

    data.addRows(outputData);

    var formatter = new google.visualization.NumberFormat({ prefix: '$', negativeColor: _outflowColor });
    formatter.format(data, 1);
    formatter.format(data, 2);
    formatter.format(data, 3);

    var options = {
      height: 400,
      hAxis: {
        slantedText: true
      },
      vAxis: {
        format: 'currency'
      },
      colors: [_incomeColor, _outflowColor, _totalColor],
      focusTarget: 'category',
      series: {
        2: { lineWidth: 0, pointSize: 0, visibleInLegend: false }
      }
    };

    var chart = new google.visualization.LineChart($graph[0]);
    chart.draw(data, options);
  }
}

$(document).on('turbolinks:load', function (){
  if ($("#finances-graph").length){
    new DrawFinanceGraph();
  }
})
